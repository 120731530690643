import React from 'react'

const AboutPerfume = () => {
  return (
    <div className='overflow-hidden w-full h-auto px-10 py-14 md:mb-[10vh]'>
        <div className='text-white w-full h-full flex flex-col items-center gap-5'>
            <div className='text-2xl md:text-8xl librefont whitespace-nowrap'>
                <p className='libre'>ABOUT US</p>
            </div>
            <div className='text-xs md:text-2xl text-center md:w-[50vw]'>
                <p >Kinghorse Perfume adalah brand parfum yang mengunakan kualitas, eksklusif, dan kemewahan, Setiap produk dirancang untuk memberikan pengalaman baru di setiap semprotan, menawarkan keharuman mewah yang tahan lama yang tak terlupakan untuk menemani setiap perjalanan anda</p>
            </div>
        </div>
    </div>
  )
}

export default AboutPerfume
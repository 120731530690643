import React from "react";
import Shop from "../assets/Perfume/shop.png";
import Tiktok from "../assets/Perfume/tiktok.png";
import Shopee from "../assets/Perfume/shopee.png";
import Tokped from "../assets/Perfume/tokped.png";
import Lazada from "../assets/Perfume/lazada.png";

const OlshopPerfume = () => {
  return (
    <div
      id="howtobuy"
      className="overflow-hidden w-full h-full flex-col lg:flex-row lg:justify-center  lg:items-center flex py-10"
    >
      <div className="lg:w-1/3">
        <div className="flex flex-col justify-center items-center w-full gap-5">
          <h1 className="text-xl  lg:text-5xl libre whitespace-nowrap">
            SHOP NOW
          </h1>
          <hr className="text-white bg-white w-40 lg:w-80" />
          <h1 className="text-center leading-[28px] lg:text-2xl">
            Discover and order now <br />
            exclusive King Horse perfume <br />
            products from our shop
          </h1>
        </div>
        <div>
          <div className="w-full flex gap-4 justify-center items-center pt-5">
            <div className="flex gap-6 lg:gap-10 px-7 py-5 mx-5 border-2 border-white rounded-xl">
              <a
                className=""
                target="_blank"
                href="https://shopee.co.id/kinghorse.official"
              >
                <img
                  src={Shopee}
                  className="w-20 object-contain drop-shadow-[5px_3px_0px_rgba(255,255,255,0.75)]"
                  alt=""
                />
              </a>
              <a
                className=""
                target="_blank"
                href="https://www.tokopedia.com/kinghorse"
              >
                <img
                  src={Tokped}
                  className="w-20 object-contain drop-shadow-[5px_3px_0px_rgba(255,255,255,0.75)]"
                  alt=""
                />
              </a>
              <a
                className="flex "
                href="https://www.tiktok.com/@kinghorse.officiall?_t=8qnnbLOndYY&_r=1"
                target="_blank"
              >
                <img
                  src={Tiktok}
                  className="w-20 object-contain drop-shadow-[5px_3px_0px_rgba(255,255,255,0.75)]"
                  alt=""
                />
              </a>
              <a
                className=""
                target="_blank"
                href="https://www.lazada.co.id/shop/king-horse-id"
              >
                <img
                  src={Lazada}
                  className="w-24 pt-2 object-contain"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className=" w-1/2 hidden lg:block lg:px-32">
        <img
          src={Shop}
          className="object-contain lg:object-contain lg:h-[70vh] lg:w-full  "
          alt=""
        />
      </div>
    </div>
  );
};

export default OlshopPerfume;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay, Pagination, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import diva from "../assets/Perfume/CATALOG GOD DIVA 2.png";
import sovereign from "../assets/Perfume/CATALOG SOVEREIGN 3.png";
import avyanna from "../assets/Perfume/CATALOG AVYANNA 1.png";
import essential from "../assets/Perfume/CATALOG ESSENTIAL 8.png";
import line from "../assets/Perfume/ELEMENT LINE.png";

const ProductDisplay = () => {
  return (
    <div  className="w-full h-full relative"> {/* Changed to h-screen for better visibility */}
      <img src={line} className="hidden md:block absolute w-full h-auto top-0 -z-50" alt="" /> {/* Adjusted z-index */}
      <Swiper
        modules={[EffectFade, Autoplay, EffectCoverflow]}
        effect="coverflow"
        loop={true} // Ensures infinite looping
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 40,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={true}
        speed={500}
        
        pagination={{ clickable: true }}
        className="h-auto z-20" // Ensure Swiper has a higher z-index than the essential image
      >
        <SwiperSlide>
          <div className="overflow-hidden flex flex-col md:flex-row justify-center items-center h-auto w-screen bg-none">
            <div className="w-screen h-auto bg-none flex justify-center items-center">
              <img src={diva} className="w-full h-full object-contain md:p-10 xl:p-20" alt="" />
            </div>
            <div className="w-screen h-auto bg-none text-white flex justify-start items-center p-8">
              <div className="w-full">
                <h1 className="text-3xl font-bold md:text-5xl">GOD DIVA</h1>
                <h2 className="font-light text-2xl">MEN / EAU DE PARFUM</h2>
                <br />
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Top Notes:</span> Chocolate
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Middle Notes</span>: Vanilla,
                  Cocoa Bean
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Base Notes: </span>Musk,
                  Amber
                </h2>
                <br />
                <div className="w-full md:w-1/2 flex text-center justify-center items-center border-white border-2 rounded-full">
                  <div className="px-3 py-2 w-full">
                    <p>SHOP NOW</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="overflow-hidden flex flex-col md:flex-row justify-center items-center h-full w-screen bg-none">
            <div className="w-screen h-auto bg-none flex justify-center items-center">
              <img src={sovereign} className="w-full h-full object-contain md:p-10 xl:p-20" alt="" />
            </div>
            <div className="w-screen h-full bg-none text-white flex justify-start items-center p-8">
              <div className="w-full">
                <h1 className="text-3xl font-bold md:text-5xl">KING SOVEREIGN</h1>
                <h2 className="font-light text-2xl">MEN / EAU DE PARFUM</h2>
                <br />
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Top Notes:</span> Mint, Lemon,
                  Green Apple
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Middle Notes</span>: Tonka Bean,
                  Ambroxan, Geranium
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Base Notes:</span>
                  Madagascar Vanilla, Virginian Cedar, Atlas Cedar, Vetiver,
                  Oakmoss
                </h2>
                <br />
                <div className="w-full md:w-1/2 flex text-center justify-center items-center border-white border-2 rounded-full">
                  <div className="px-3 py-2 w-full">
                    <p>SHOP NOW</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="overflow-hidden flex flex-col md:flex-row justify-center items-center h-full w-screen bg-none">
            <div className="w-screen h-auto bg-none flex justify-center items-center">
              <img src={avyanna} className="w-full h-full object-contain md:p-10 xl:p-20" alt="" />
            </div>
            <div className="w-screen h-full bg-none text-white flex justify-start items-center p-8">
              <div className="w-full">
                <h1 className="text-3xl font-bold md:text-5xl">AVYANNA</h1>
                <h2 className="font-light text-2xl">MEN / EAU DE PARFUM</h2>
                <br />
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Top Notes:</span> Wild Berries,
                  Pear, Peach, Mandarin Orange
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Middle Notes</span>: Apple
                  Blossom, Tiare Flower, Jasmine, Water Lily
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Base Notes:</span>
                  Vanilla, Coconut, Orchid, Musk, Heliotrope, Sandalwood
                </h2>
                <br />
                <div className="w-full md:w-1/2 flex text-center justify-center items-center border-white border-2 rounded-full">
                  <div className="px-3 py-2 w-full">
                    <p>SHOP NOW</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="overflow-hidden flex flex-col md:flex-row justify-center items-center h-full w-screen bg-none">
            <div className="w-screen h-auto bg-none flex justify-center items-center">
              <img src={essential} className="w-full h-full object-contain md:p-10 xl:p-20" alt="" />
            </div>
            <div className="w-screen h-full bg-none text-white flex justify-start items-center p-8">
              <div className="w-full">
                <h1 className="text-3xl font-bold md:text-5xl">ESSENTIAL</h1>
                <h2 className="font-light text-2xl">MEN / EAU DE PARFUM</h2>
                <br />
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Top Notes:</span> Apple, Cloves, Lemon
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Middle Notes</span>: Apple
                  Blossom, Tiare Flower, Jasmine, Water Lily
                </h2>
                <h2 className="text-xs md:text-2xl">
                  <span className="font-bold">Base Notes:</span>
                  Vanilla, Cedar, Amber, Musk
                </h2>
                <br />
                <div className="w-full md:w-1/2 flex text-center justify-center items-center border-white border-2 rounded-full">
                  <div className="px-3 py-2 w-full">
                    <p>SHOP NOW</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ProductDisplay;
